<template>
  <div>
    <b-container class="p-5">
      <h3>查找组织</h3>
      <b-form-group
          label="Search"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="w-50 mb-3">
        <b-input-group>
          <b-form-input
              id="filter-input"
              v-model="input"
              type="search"
              placeholder="Type to Search"></b-form-input>
          <b-input-group-append>
            <b-button variant="primary" @click="getOrganizations">查询</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <div>
        <b-table :items="organizations" :fields="fields" striped responsive="sm">
          <template #cell(show_details)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2">
              {{ row.detailsShowing ? '收起' : '展开' }}详情
            </b-button>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"><b>编号:</b></b-col>
                <b-col>{{ row.item.id }}</b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"><b>名称:</b></b-col>
                <b-col>{{ row.item.name }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"><b>类型:</b></b-col>
                <b-col>{{ row.item.type }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"><b>上级归属编号:</b></b-col>
                <b-col>{{ row.item.superiorId }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
        <!--    分页选择-->
        <b-row>
          <b-col sm="5" md="6" class="my-1">
            <b-form-group
                label="Per page"
                label-for="per-page-select"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0">
              <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <!--分页-->
          <b-col sm="7" md="4" class="my-1">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
                class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
/*筛选组织名称，然后选择，查询*/
import {showOrganizationByName, showMyOrganizations} from "@/api";
import {showOrganizationsByType} from "../../api";

export default {
  name: "SearchOrganization",
  data() {
    return {
      totalRows: 100,
      perPage: 5,
      pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
      input: '',
      pageSize: 50,
      currentPage: 1,
      organizations: [],
      fields: [{key: 'id', label: '组织号'}, {key: 'name', label: '组织名'}, {key: 'type', label: '类型'},
        {key: 'superiorId', label: '上层组织'}
        , 'show_details']
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  created() {
    /*加载组织数据*/
    this.loadOrganizationData()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    /*加载组织信息*/
    loadOrganizationData() {
      showMyOrganizations(1, 100).then((res) => {
        if (res.data.status) {
          this.organizations = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    /*根据名字模糊查询*/
    getOrganizations() {
      showOrganizationsByType(this.input,this.currentPage, this.pageSize).then((res) => {
        if (res.data.status) {
          this.organizations = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
